import React, { lazy, Suspense } from 'react'
import Spinner from '../../components/Spinner/Spinner';
const WebcamView = lazy(() => import('../../components/Webcam/Webcam'))

function HomeView() {
  return (
    <Suspense fallback={<Spinner />}>
      <WebcamView />
    </Suspense>
  )
}

export default HomeView;
