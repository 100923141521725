import React, { lazy, Suspense } from 'react'
import HomeView from '../pages/welcome/HomeView';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Spinner from '../components/Spinner/Spinner';
import { ThemeProvider } from '@material-ui/core';
import theme from '../themes/theme';
const SubmitForm = lazy(() => import('../pages/submitForm/SubmitForm'))
const ThankYou = lazy(() => import('../pages/thankYou/ThankYou'))
const Login = lazy(() => import('../pages/Login/Login'))
const Participants = lazy(() => import('../pages/participants/Participants'))

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Suspense fallback={<Spinner />}>
        <Router>
          <Routes>
            <Route exact path="/" element={<HomeView />} />
            <Route exact path="/submit-form" element={<SubmitForm />} />
            <Route exact path="/thank-you" element={<ThankYou />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/participants" element={<Participants />} />
          </Routes>
        </Router>
      </Suspense>
    </ThemeProvider>
  );
}

export default App