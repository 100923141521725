
import { createTheme } from "@material-ui/core";

const theme = createTheme({
    palette: {
        primary: {
            main: '#FDAF18',
        },
        secondary: {
            main: '#A81D1D',
        },
    },
});

export default theme;